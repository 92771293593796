.header-area {
  top: 0;
  right: 0;
  left: 0;
  z-index: 91;
  height: auto;
  background-color: rgba(255,255,255, .95);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: var(--bs-box-shadow-sm) !important;
  .nav-link{
    font-family:var(--primary-font) ;
  }
  .flash-top{
    background-color: var(--bs-green);
    color:#ffffff;
    font-size: .8rem;
    font-weight: 400;
    padding: 1px;
    .nav-link{
      color: #3c3b35;
      font-weight: 400;
      font-size: 14px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    h6{font-weight: 700;color: #2c2b25;letter-spacing: .5px;}
  }
  .topbar{
    padding: 0.5rem 0;
    .header-left-side {
      position: relative;
      button {
        &.header-burger-menu {
          margin-right: 22px;
          cursor: pointer;
          font-size: 22px;
          top: 0;
          border: none;
          svg{
            height:27px;
            width:auto;
          }

          &:hover {
            color: var(--splash-primary-color);
          }
        }
      }
      .logo{
        img{width: auto;height:36px}
      }
    }
    .header-right-side {
      .dropdown {
        margin: 0;
        &.language-dropdown {
          .dropdown-menu {
            width: 200px;

            .dropdown-body {
              li {
                padding-left: 63px;
              }
            }
          }
        }
        .btn{
          padding: 0 18px;
          i{font-size: 23px;}
          .dot-badge {
            top: -9px;
            right: 9px;
            width: 17px;
            height: 17px;
            font-size: 9px;
            line-height: 17px;
            background-color: #dd0000;
            color: #fff !important;
            border-radius: 50%;
            position: absolute;
            display: inline-block;
          }
        }
        #gabeCurrLangF{
          height: 23px;
          width: auto;
        }
        #gabeCurrLangT{
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
        }
        .dropdown-menu {
          width: 375px;
          font-size: 14px;
          box-shadow: 0px 4px 34px rgba(101, 96, 240, 0.1);
          margin: {
            top: 9px;
            right: -9px !important;
          }
          padding: {
            top: 9px;
            bottom: 9px;
          }
          .title {
            padding: {
              left: 28px;
              right: 28px;
              bottom: 27px;
            }
            .link-btn {
              font-size: 13px;
            }
          }
          .dropdown-body {
            li {
              border-bottom: 1px dashed #e7e2e2;
              padding: 18px 28px 18px 87px;
              letter-spacing: 0.01em;

              .icon {
                top: 50%;
                left: 28px;
                width: 44px;
                height: 44px;
                font-size: 20px;
                background: #f2f1f9;
                transform: translateY(-50%);
                color: var(--splash-primary-color);

                i,
                img {
                  left: 0;
                  right: 0;
                  top: 50%;
                  line-height: 1;
                  position: absolute;
                  transform: translateY(-50%);
                  margin: {
                    left: auto;
                    right: auto;
                  }
                }
              }
              img {
                top: 50%;
                left: 28px;
                transform: translateY(-50%);
              }
              span {
                font-size: 15px;
                margin-bottom: 3px;

                span {
                  font-size: 12px;
                }
              }
              .unread {
                top: 50%;
                right: 25px;
                transform: translateY(-50%);
              }
              &:nth-child(2),
              &:nth-child(5),
              &:nth-child(8) {
                .icon {
                  background: #f3f7f9;
                  color: #39b2de;
                }
              }
              &:nth-child(3),
              &:nth-child(6),
              &:nth-child(9) {
                .icon {
                  background: #f1faf8;
                  color: #06b48a;
                }
              }
              &:last-child {
                border-bottom: 0;
              }
            }
          }
          .dropdown-footer {
            padding-top: 18px;

            .link-btn {
              font-size: 13px;

              &::before {
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                width: 100%;
                content: "";
                position: absolute;
                transition: var(--transition);
                background: var(--splash-primary-color);
              }
              &:hover {
                &::before {
                  transform: scaleX(0);
                }
              }
            }
          }
        }
      }
      .dark-swtich-btn {
        margin-right: 4px;

        .switch-toggle {
          top: 1px;
          width: 36px;
          height: 36px;
          font-size: 17px;
          background-color: #f2f1f9;
          color: var(--splash-primary-color);

          i {
            left: 0;
            right: 0;
            top: 50%;
            line-height: 1;
            margin-top: 1px;
            position: absolute;
            transform: translateY(-50%);
          }
          &:hover {
            background-color: var(--splash-primary-color);
            color: var(--splash-white-color);
          }
        }
      }
    }
  }
  .search-box {
    flex-grow: 1;
    margin: 0 1rem;
    padding: 0 3.5rem;
    .form-control {
      background-color: #ffffff;
      padding: {
        left: 15px;
        right: 15px;
      }
    }
    .btn {
      z-index: 6;
      padding: 5px 18px;
    }
  }

  .productmenu{
    border-top:1px solid #eee;
    .dropdown-menu {
      animation: 0.5s slideup;
    }

    @keyframes slideup {
      from {
        transform: translateY(-20%);
      }

      to {
        transform: translateY(0);
      }
    }
    .nav-link {
      display: block;
      font-size: 15px;
      color: #636363!important;
    }
    .dropdown-item{
      font-size: 14px;
      color: #636363!important;
    }
    .nav-link.dropdown-toggle.show{
      color: var(--base-color)!important;;
    }
  }
  &.sticky {
    top: 0;
    position: fixed;
    box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
    .header-burger-menu.d-lg-none{
      display: block!important;
    }
    .flash-top{
      display: none;
    }

    .navbar-collapse .offcanvas-header{
      display:flex;
    }
  }
}

// Responsive
@media only screen and (max-width: 767px) {
  .header-area {
    .navbar-collapse .offcanvas-header{
      display:flex;
    }
    .topbar{
      padding-top: 0.25rem;
      .header-left-side {
        button {
          &.header-burger-menu {
            margin-right: 15px;
          }
        }
        .logo{
          img{height: 23px !important;}
        }
      }
      .header-right-side {
        .dropdown {
          .btn{
            padding: 3px 9px;
            i{
              font-size: 18px;
            }
            .dot-badge {
              top: -2px;
              right: 4px;
              width: 15px;
              height: 15px;
              font-size: 8px;
              line-height: 15px;
              font-weight: 600;
            }
          }
          #gabeCurrLangF{
            height: 18px!important;
          }
          #gabeCurrLangT{
            font-size: 12px;
          }
          .dropdown-menu {
            width: 270px;
            margin: {
              left: -21px !important;
              top: 9px !important;
              right: 0 !important;
            }
            padding: {
              top: 15px;
              bottom: 15px;
            }
            .title {
              padding: {
                left: 15px;
                right: 15px;
                bottom: 15px;
              }
            }
            .dropdown-body {
              font-size: 13px;

              li {
                padding: 15px;

                .icon {
                  left: 15px;
                  width: 40px;
                  height: 40px;
                  font-size: 18px;
                }
                img {
                  left: 15px;
                }
                span {
                  font-size: 14px;
                }
                .unread {
                  right: 15px;
                }
              }
            }
            .dropdown-footer {
              padding-top: 12px;
            }
          }
        }
        .dark-swtich-btn {
          margin-right: 2px;

          .switch-toggle {
            top: 3px;
            width: 30px;
            height: 30px;
            font-size: 15px;
          }
        }
      }
    }
    .productmenu{
      border-top:0;
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-area {
    .navbar-collapse .offcanvas-header{
      display:flex;
    }
    .header-left-side {
      .search-box {
        padding: 0;
        margin-right: 0;
        .btn{
          padding: 0 5px;
        }
        .form-control{
          padding: 0 9px !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area {
    .navbar-collapse .offcanvas-header{
      display:flex;
    }
    .header-left-side {
      .logo{
        display: flex;
        flex-grow: 0;
        justify-content: center;
        img{height: 27px !important;}
      }
      button {
        &.header-burger-menu {
          margin-right: 18px;
          font-size: 20px;
        }
      }
      .search-box {
        padding: 0!important;
        margin-right: 0;
      }
    }
    .header-right-side {
      .btn{

        i{
          font-size: 19px!important;
          height: 19px!important;
        }
        padding: 3px 6px;
        &.usrbt{
          padding: 4px;
        }
      }
      .dropdown {
        #gabeCurrLangF{
          height: 19px!important;
        }
        #gabeCurrLangT{
          font-size: 12px;
        }
        .dropdown-menu {
          width: 300px;
          padding: {
            top: 20px;
            bottom: 20px;
          }
          .title {
            padding: {
              left: 20px;
              right: 20px;
              bottom: 20px;
            }
          }
          .dropdown-body {
            li {
              padding: 18px 20px 18px 78px;

              .icon {
                left: 20px;
              }
              img {
                left: 20px;
              }
              span {
                font-size: 14px;
              }
              .unread {
                right: 20px;
              }
            }
          }
          .dropdown-footer {
            padding-top: 15px;
          }
        }
        &.apps-dropdown {
          margin-right: 8px;

          .dropdown-menu {
            width: 150px;

            .dropdown-body {
              li {
                padding-left: 65px;
              }
            }
          }
        }
        &.language-dropdown {
          margin-right: 12px;

          .dropdown-menu {
            width: 200px;

            .dropdown-body {
              li {
                padding-left: 63px;
              }
            }
          }
        }
        &.profile-dropdown {
          margin-left: 12px;
        }
      }
      .dark-swtich-btn {
        margin-right: 5px;

        .switch-toggle {
          top: 1px;
          width: 35px;
          height: 35px;
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-area {
    .header-left-side {
      .logo{
        img{height: 32px !important;}
      }
    }
    .header-right-side {

      .dropdown {
        margin: {
          left: 15px;
          right: 15px;
        }
      }
      .dark-swtich-btn {
        .switch-toggle {
          top: 1px;
        }
      }
    }
  }
}

