/* Contact form */
.select-style {
    select {
        background-image: url("../images/contact-form-down-arrow.jpg");
        background-repeat: no-repeat;
        background-position: top 25px right 30px;
        cursor: pointer;
        -webkit-appearance: none;
        background-color: transparent;
    }
}

.form-floating {
  &:not(.form-control:disabled)::before {
    background: none;
  }
  > label {
    color: #999;
    display: none;
  }
  &.input-group label {
    margin-left: 0.05rem !important;
  }
  > textarea.form-control {
    &:focus,
    &:not(:placeholder-shown) {
      padding-top: 1.3rem;
    }
    &:-webkit-autofill {
      padding-top: 1.3rem;
    }
  }
  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-control-plaintext,
  > .form-select {
    ~ label {
      color: #999;
      display: inline-block;
      transform: scale(0.75) translateY(-1.5rem) translateX(0.15rem);
      &::after {
        background-color: transparent;
      }
    }
  }
}

.terms-condition-box {
    label {
        display: block;
    }
    input[type="checkbox"] {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 4px;
        left: 0;
        &:checked {
            + .box {
                &:after {
                    display: block;
                }
            }
        }
        &.is-invalid {
            + .box {
                &:before {
                    border-color: #dc3545;
                }
            }
        }
    }
    .box {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        display:inline-block;
        &:before {
            content: '';
            width: 20px;
            height: 20px;
            background-color: transparent;
            border: 1px solid var(--extra-medium-gray);
            position: absolute;
            top: 5px;
            left: 0;
            @include prefix(border-radius, 4px, webkit);
        }
        &:after {
            position: absolute;
            left: 7px;
            top: 9px;
            content: '';
            width: 6px;
            height: 10px;
            display: none;
            border: solid var(--dark-gray);
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

/* Contact form style 01 */
.contact-form-style-01 {
    input, textarea {
        padding-left: 55px;
    }
    .form-group {
        .form-icon {
            position: absolute;
            top: 50%;
            @include prefix(transform, translateY(-50%), webkit);
            padding-top: 2px;
            left: 25px;
        }
        &.form-textarea {
            .form-icon {
                top: 12px;
                @include prefix(transform, translateY(0), webkit);
            }
        }
    }
}

/* Contact form style 03 */
.contact-form-style-03 {
    input, textarea {
        outline: none;
        border: none;
        border-bottom: 1px solid;
        background: transparent;
    }
    select {
        outline: none;
        border: none;
        border-bottom: 1px solid;
    }
    input, select, textarea, .form-control {
        &:focus {
            border: 0;
            border-bottom: 1px solid #c2c2c2;
        }
    }
    textarea {
        resize: none;
    }
    .form-group {
        .form-icon {
            position: absolute;
            top: 50%;
            right: 5px;
            @include prefix(transform, translateY(-50%), webkit);
        }
        &.form-textarea {
            .form-icon {
                top: 10px;
                @include prefix(transform, translateY(0), webkit);
            }
        }
    }
    .form-control, .form-control.is-invalid, .was-validated .form-control:invalid {
        background-position: right 35px center !important;
    }
}

/* Contact form style 06 */
.contact-form-style-06 {
    input, textarea {
        color: var(--white);
        &:focus {
            color: var(--white);
        }
    }
    input::placeholder, textarea::placeholder {
        color: var(--light-medium-gray);
    }
    .select-style {
        select {
            color: var(--light-medium-gray);
            background-image: url("../images/contact-form-arrow-white.png");
            background-position: top 25px right 20px;
            padding-right: 45px;
            option {
                color: var(--dark-gray);
            }
        }
    }
}

/* Contact form style 07 */
.contact-form-style-07 {
    input, textarea {
        outline: none;
        border: none;
        border-bottom: 1px solid;
        background: transparent;
    }
    select {
        outline: none;
        border: none;
        border-bottom: 1px solid;
    }
    input, select, textarea, .form-control {
        &:focus {
            border: 0;
            border-bottom: 1px solid #c2c2c2;
        }
    }
    textarea {
        resize: none;
    }
    .form-group {
        .form-icon {
            position: absolute;
            top: 50%;
            right: 5px;
            @include prefix(transform, translateY(-50%), webkit);
        }
        &.form-textarea {
            .form-icon {
                top: 10px;
                @include prefix(transform, translateY(0), webkit);
            }
        }
    }
    .form-control, .form-control.is-invalid, .was-validated .form-control:invalid {
        background-position: right 35px center !important;
    }
}
