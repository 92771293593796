/* Interactive banner style 01 */
.interactive-banner-style-01 {
    figure {
        @include prefix(transition, all 700ms cubic-bezier(0.61, 1, 0.88, 1), webkit);
        .icon-box {
            opacity: 0;
            position: relative;
            top: 15px;
        }

        figcaption {
            position: absolute;
            bottom: 0;
        }
        &:hover {
            @include prefix(transform, translateY(-5px), webkit);
            img {
                @include prefix(transform, scale(1.1), webkit);
                @include prefix(transition, all 700ms cubic-bezier(0.61, 1, 0.88, 1), webkit);

            }
            .icon-box {
                opacity: 1;
                top: 0;
            }
            .box-overlay {
                opacity: 0.5;
            }
        }
    }
}

/* Interactive banner style 02 */
.interactive-banner-style-02 {
    &.hover-box *{
        will-change: auto !important;
    }
    figure {
        figcaption {
            .features-icon {
                margin-bottom: -45px;
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    &:hover {
        figure {
            img{
                @include prefix(transform, scale(1.1), webkit);
            }
            figcaption {
                .features-icon {
                    margin-bottom: 20px;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

/* Interactive banner style 03 */
.interactive-banner-style-03 {
    figure {
        position: relative;
        img {
            @include prefix(transition, all 700ms cubic-bezier(0.61, 1, 0.88, 1), webkit);
        }
        figcaption {
            position: absolute;
            bottom: 0;
            top: 0;
            background-color: rgba(0,0,0,.2);
        }
        &:hover {
            img {
                @include prefix(transform, scale(1.08), webkit);
            }
        }
    }
}

/* Interactive banner style 04 */
.interactive-banner-style-04 {
    figure {
        @include prefix(transition, all 700ms cubic-bezier(0.5, 1, 0.89, 1), webkit);
        * {
            @include prefix(transition, all 700ms cubic-bezier(0.5, 1, 0.89, 1), webkit);
        }
        figcaption {
            position: absolute;
            bottom: 0;
            .features-content {
                position: absolute;
                left: 25px;
                top: 25px;
                width: calc(100% - 50px);
                height: calc(100% - 50px);
                opacity: 0;
                visibility: hidden;
                @include prefix(transform, translateY(20px), webkit);
                @include prefix(transition, all 500ms cubic-bezier(0.5, 1, 0.89, 1), webkit);
                .box-overlay {
                    height: 0%;
                    @include prefix(transition, all 400ms cubic-bezier(0.5, 1, 0.89, 1), webkit);
                }
            }
            .box-button {
                opacity: 1;
                visibility: visible;
                position: relative;
                bottom: 0;
                @include prefix(transition, all 500ms cubic-bezier(0.5, 1, 0.89, 1), webkit);
            }
        }
        &:hover {
            @include prefix(transform, translateY(-5px), webkit);
            figcaption {
                .features-content {
                    opacity: 1;
                    visibility: visible;
                    @include prefix(transform, translateY(0), webkit);
                }
                .box-overlay {
                    height: 100%;
                }
                .box-button {
                    opacity: 0;
                    visibility: hidden;
                    position: relative;
                    bottom: -70px;
                }
            }
        }
    }
}

/* Interactive banner style 05 */
.interactive-banner-style-05 {
    figure {
        img {
            @include prefix(transition, all 700ms cubic-bezier(0.61, 1, 0.88, 1), webkit);
        }
        figcaption {
            .btn {
                margin-bottom: -50px;
                visibility: hidden;
                opacity: 0;
                transition-timing-function: linear;
                @include prefix(transition, all 700ms cubic-bezier(0.61, 1, 0.88, 1), webkit);
            }
            .content-arrow {
                position: absolute;
                right: 0;
                opacity: 0;
                top: 50%;
                @include prefix(transform, translateY(5px), webkit);
            }

        }
        &:hover {
            img {
                @include prefix(transform, scale(1.08), webkit);
            }
            figcaption {
                img {
                    @include prefix(transform, scale(1), webkit);
                }
                .content-arrow {
                    opacity: 1;
                    @include prefix(transform, translateY(-50%), webkit);
                }
            }
            figcaption {
                .btn {
                    margin-bottom: 0;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
    .atropos {
        &:hover {
            .box-overlay {
                opacity: 1;
                visibility: visible;
            }
            img {
                @include prefix(transform, scale(1.08), webkit);
            }
        }
    }
}

/* Interactive banner style 06 */
.interactive-banner-style-06 {
    position: relative;
    overflow: hidden;
    .banners-icon {
        z-index:9;
        @include prefix(transition, 350ms cubic-bezier(0.12, 0, 0.39, 0), webkit);
    }
    img {
        @include prefix(transition, 1s cubic-bezier(0.12, 0, 0.39, 0), webkit);
        width: 100%;
    }
    .interactive-banners-content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        transform: translateY(calc(100% - 155px));
        @include prefix(transition, 550ms cubic-bezier(0.12, 0, 0.1, 0), webkit);
        .interactive-banners-content-text {
            opacity: 0;
            visibility: hidden;
            @include prefix(transition, 350ms cubic-bezier(0.12, 0, 0.39, 0), webkit);
        }
    }
    .box-overlay {
        height: 25%;
        bottom: 0;
        top: auto;
        visibility: visible;
        opacity:0;
        @include prefix(transition, 350ms cubic-bezier(0.12, 0, 0.39, 0), webkit);
    }
    &:hover {
        img {
            @include prefix(transform, scale(1.3), webkit);
            @include prefix(transition, 3s cubic-bezier(0.12, 0, 0.39, 0), webkit);
        }
        .icon-hover-base-color {
            color:var(--base-color) !important;
        }
        .interactive-banners-content {
            transform: translateY(0);
            .interactive-banners-content-text {
                opacity: 1;
                visibility: visible;
            }
        }
        .box-overlay {
            height: 100%;
            visibility: visible;
            opacity:1;
        }
    }
}

/* Interactive banner style 07 */
.interactive-banner-style-07 {
    figure {
        position:relative;
        overflow:hidden;
        > img {
            @include prefix(transition, 0.6s cubic-bezier(0.12, 0, 0.39, 0), webkit);
            @include prefix(transform, scale(1), webkit);
        }
        .overlay-bg {
            @include prefix(transition, 0.4s cubic-bezier(0.12, 0, 0.39, 0), webkit);
        }
        figcaption {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
        .hover-hide-content {
            position: absolute;
            bottom: 50px;
            left: 0;
            width: 100%;
            @include prefix(transition, 1s, webkit);
        }
        .hover-show-content {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            bottom: 50px;
            @include prefix(transition, 0.4s cubic-bezier(0.12, 0, 0.39, 0), webkit);
            @include prefix(transform, scale(1), webkit);
        }
        &:hover {
            > img {
                @include prefix(transform, scale(1.1), webkit);
            }
            .overlay-bg {
                opacity:0.7;
            }
            .hover-hide-content {
                opacity: 0;
                visibility: hidden;
                @include prefix(transition, 0.1s, webkit);
            }
            .hover-show-content {
                opacity: 1;
                visibility: visible;
                @include prefix(transform, scale(1.2), webkit);
            }
        }
    }
}

/* Interactive banner style 08 */
.interactive-banner-style-08 {
    figure {
        &:hover {
            img {
                @include prefix(transform, scale(1.08), webkit);
                @include prefix(transition, 1.5s, webkit);
            }
            .box-overlay {
                opacity: 1;
                visibility: visible;
            }
            figcaption {
                .circle-box {
                    background-color: var(--white) !important;
                    @include prefix(transform, scale(1.1), webkit);
                    @include prefix(transition, all 500ms cubic-bezier(0.61, 1, 0.88, 1.5), webkit);
                }
            }
        }
    }
}

/* Interactive banner style 09 */
.interactive-banner-style-09 {
    img {
        @include prefix(transition, all 1.5s cubic-bezier(.19,1,.22,1), webkit);
    }
    .image-content {
        position: absolute;
        top: 0;
        .content-title {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            position: relative;
            opacity: 1;
            visibility: visible;
            will-change: transform;
            @include prefix(transform, translateY(0), webkit);
            @include prefix(transition, 0.4s cubic-bezier(0.12, 0, 0.39, 0), webkit);
        }
        .content-title-hover {
            position: absolute;
            width: auto;
            left: 0;
            bottom: 0;
            visibility: hidden;
            will-change: transform;
            @include prefix(transform, translateY(20px), webkit);
            @include prefix(transition, 0.4s cubic-bezier(0.12, 0, 0.39, 0), webkit);
        }
        .content-arrow {
            will-change: transform;
            position: absolute;
            right: 0;
            opacity: 0;
            top: 50%;
            @include prefix(transform, translateY(10px), webkit);
            @include prefix(transition, all 0.3s cubic-bezier(0.12, 0, 0.39, 0), webkit);
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .label {
            @include prefix(transition, 0.3s cubic-bezier(0.12, 0, 0.39, 0), webkit);
        }
        .hover-label-icon {
            i {
                opacity: 0;
                position: absolute;
                top: 20px;
                left: 0;
                @include prefix(transition, 0.3s cubic-bezier(0.12, 0, 0.39, 0), webkit);
            }
        }
        &:hover {
            .content-arrow {
                opacity: 1;
                @include prefix(transform, translateY(-42%), webkit);
            }
            .content-title {
                opacity: 0;
                visibility: hidden;
                @include prefix(transform, translateY(-20px), webkit);
            }
            .content-title-hover {
                visibility: visible;
                @include prefix(transform, translateY(-3px), webkit);
            }
            .box-overlay {
                opacity: 1;
                visibility: visible;
            }
            .hover-label-icon {
                .label {
                    opacity: 0;
                    margin-top: -20px;
                }
                i {
                    opacity: 1;
                    top: 0;
                }
            }
        }
    }
    &:hover{
        img{
            @include prefix(transform, scale(1.1), webkit);
        }
    }
}
