/* ===================================
    03. Reset
====================================== */

.alt-font {
    font-family: var(--alt-font);
}
.primary-font {
    font-family: var(--primary-font) !important;
}
html {
    font-size: 16px;
}
body {
    font-family: var(--primary-font);
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
    color: var(--medium-gray);
    -moz-osx-font-smoothing: grayscale;
    word-break: break-word;
    -webkit-font-smoothing: antialiased;
}
section {
    padding-top: 110px; padding-bottom: 110px;
    &.half-section {
        padding-top: 70px; padding-bottom: 70px;
    }
    &.big-section {
        padding-top: 130px; padding-bottom: 130px;
    }
    &.extra-big-section {
        padding-top: 160px; padding-bottom: 160px;
    }
}
img {
    max-width: 100%;
    height: auto;
}
ul {
    li {
        list-style: none;
    }
}
a {
    color: var(--medium-gray);
    @include prefix(transition, 0.3s, webkit);
    text-decoration: none;
    &:hover {
        color: var(--base-color);
        text-decoration: none;
    }
}
p {
    margin-bottom: 25px;
}
.last-paragraph-no-margin {
    p {
        &:last-of-type {
            margin-bottom:0;
        }
    }
}
video {
    background-size: cover;
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}
:focus-visible {
    outline: none;
}
[class*="line-icon"], [class*=" line-icon"], [class*="bi-"], [class*="feather"], [class*="ti-"] { display: inline-block; }
.form-control, .form-control.is-invalid, .was-validated .form-control:invalid {
    background-position: right 20px center !important;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
}
.g-recaptcha.is-invalid iframe {
    border:1px solid #dc3545 !important;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding-right: 15px;
    padding-left: 15px;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.row>* {
    padding-right: 15px;
    padding-left: 15px;
}
.row.g-0 {
    margin-right: 0;
    margin-left: 0;
}
.row.g-0>* {
    padding-right: 0;
    padding-left: 0;
}
.row.gutter-very-small {
    margin-left: -5px;
    margin-right: -5px;
}
.row.gutter-very-small>* {
    padding-right: 5px;
    padding-left: 5px;
}
.row.gutter-small {
    margin-left: -10px;
    margin-right: -10px;
}
.row.gutter-small>* {
    padding-right: 10px;
    padding-left: 10px;
}
