/*

Table of contents

    01. Google font
    02. CSS color variable
    03. Reset
    04. Typography
    05. General
    06. Background and border
    07. Width
    08. Height
    09. Loop
    10. Header
    11. Page title
    12. Layout
    13. Elements
    14. Blog
    15. Portfolio
    16. Shop
    17. Footer

*/
// @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700;800;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Afacad:wght@400..700&display=swap');



// Initialize
@import "icon/icon/feather";
// @import "./fonts";
@import "bootstrap";
@import "variables"; // For SCSS variables
@import "theme-vars"; // For CSS variables
@import "mixins/mixins";

// Core CSS
@import "core/reset";
@import "core/typography";
@import "core/classes";
@import "core/loop";

// Header
@import "header/headernew";

// Page title
@import "titlebar/page-titles";

// Layouts
@import "layouts/sidebar";
// @import "layouts/grid";

// Elements
@import "elements/accordions-and-toggles";
@import "elements/alert-box";
@import "elements/blockquote";
@import "elements/buttons";
@import "elements/clients";
@import "elements/columns";
@import "elements/contact-form";
@import "elements/content-carousel";
@import "elements/countdown";
@import "elements/counters";
@import "elements/divider";
//@import "elements/drop-caps";
@import "elements/event";
@import "elements/fancy-heading";
@import "elements/fancy-text-box";
//@import "elements/google-map";
@import "elements/heading";
@import "elements/highlights";
@import "elements/icon-with-text";
@import "elements/image-gallery";
//@import "elements/instagram";
@import "elements/interactive-banners";
@import "elements/lists";
@import "elements/media";
//@import "elements/modal-popup";
//@import "elements/parallax-scrolling";
//@import "elements/particles";
//@import "elements/pie-chart";
//@import "elements/pricing-table";
@import "elements/process-step";
@import "elements/progress-bar";
@import "elements/reviews";
@import "elements/rotate-box";
@import "elements/services";
@import "elements/sliding-box";
//@import "elements/social-icons";
//@import "elements/subscribe";
//@import "elements/tab";
//@import "elements/team";
@import "elements/testimonials";
@import "shortcode/sidebar";

// Blog
@import "blog/blog";

// Portfolio
//@import "portfolio/portfolio";

// Shop
@import "shop/shop";

// Footer
@import "footer/footer";

/* border color */
label{
  border:0!important ;
}
.btn-close {
  position: absolute;
  top: 15px;
  right: 15px;
}
.opacity-05{
  opacity: .05 !important;
}
.border-color-light-gray {
  border-color: #DCD7D4 !important;
}
.fbox-hover:hover {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}
.diskon {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-transform: translate(10px, 10px);
  transform: translate(10px, 10px);
}
.animation-rotation {
  -webkit-animation: rotation 8s infinite linear;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
.qviewloader, .cartloader {
  font-weight: 500;
  font-size: 15px;
  padding-bottom: 8px;
  background: linear-gradient(currentColor 0 0) 0 100%/0% 3px no-repeat;
  animation: l2 2s linear infinite;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.cartloader:before {
  content:"Loading item to cart..."
}

.qviewloader:before {
  content:"Loading item details..."
}
@keyframes l2 {to{background-size: 100% 3px}}
.drift-pane{position:fixed;height: calc(100vh - 130px);top:120px;width:45%;left:50%;z-index:3;pointer-events: none;}
.drift-bounding-box,.drift-zoom-pane{position:absolute;pointer-events:none}@keyframes noop{0%{zoom:1}}@-webkit-keyframes noop{0%{zoom:1}}.drift-zoom-pane.drift-open{display:block}.drift-zoom-pane.drift-closing,.drift-zoom-pane.drift-opening{animation:noop 1ms;-webkit-animation:noop 1ms}.drift-zoom-pane{overflow:hidden;width:100%;height:100%;top:0;left:0}.drift-zoom-pane-loader{display:none}.drift-zoom-pane img{position:absolute;display:block;max-width:none;max-height:none}

relanimation {
  .dot {
    box-shadow: none;

    &:before,
    &:after {
      animation: none;
      display: none;
    }
  }
}

relanimation2 {
  .dot {
    background-image: linear-gradient(-180deg, #FFE27A 0%, #F689B9 100%);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.20), 0 -6px 14px 4px rgba(255, 237, 186, 0.50), 0 6px 14px 4px rgba(242, 141, 185, 0.50), inset 0 1px 3px 0 rgba(255, 255, 255, 0.50), inset 0 1px 0 0 rgba(255, 245, 217, 0.40);

    &:before,
    &:after {
      animation: none;
      display: none;
    }
  }
}


.dot {
  margin: 0 auto;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: rgba(248, 255, 149, 0.5);
  box-shadow: 0 -2px 5px 0 rgba(131, 252, 127, 0.726), 0 2px 2px 2px rgba(246, 248, 114, 0.815), inset 0 1px 1px 1px rgba(255, 69, 69, 0.925);
  transition: transform 200ms ease-out;

  &:hover {
    cursor: pointer;
    transform: scale(1.4);
  }

  &:before {
    content: "";
    animation: ripple 4s infinite;
    animation-timing-function: ease-out;
    height: 1000%;
    width: 1000%;
    position: absolute;
    top: -65px;
    left: -65px;
    display: block;
    box-sizing: border-box;
    border-radius: 100%;
    // border:1px solid #F28DB9;
    box-shadow: 0 0 5px 5px #fffb01;
  }

  &:after {
    content: "";
    animation: ripple-delayed 4s infinite;
    animation-timing-function: ease-out;
    height: 1000%;
    width: 1000%;
    position: absolute;
    top: -65px;
    left: -65px;
    display: block;
    box-sizing: border-box;
    border-radius: 100%;
    // border:1px solid #F28DB9;
    box-shadow: 0 0 3px 3px #ff7272;
  }
}

@keyframes ripple {
  0% {
    transform: scale(0.1);
    opacity: 0.3;
  }

  2.5% {
    opacity: 0.9;
  }

  50% {
    transform: scale(0.8);
    opacity: 0.3;
  }

  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes ripple-delayed {
  0% {
    transform: scale(0.1);
    opacity: 0.3;
  }

  6% {
    opacity: 0.8;
    transform: scale(0.1);
  }

  100% {
    transform: scale(0.8);
    opacity: 0.3;
  }
}
.hoverpro{opacity:0}
.outerdot, .hoverpro {cursor: pointer;}
.outerdot:hover .hoverpro {
  opacity:1;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: transform ease 300ms;
}
.shop-sidebar .shop-filter li .item-qty {
  font-size: 10px;
  font-weight: 600;
}
.pagination-style-01 .page-item.active .page-link, .pagination-style-01 .page-item .page-link:hover {
  background: var(--dark-gray);
  color: var(--white);
}
.pagination-style-01 .page-item:first-child .page-link, .pagination-style-01 .page-item:last-child .page-link {
  background: transparent;
  box-shadow: none !important;
  color: var(--medium-gray);
}
.pagination-style-01 .page-item:first-child .page-link:hover, .pagination-style-01 .page-item:last-child .page-link:hover {
  color: var(--dark-gray);
}
