.sidebar-area {
  .logo{
    height: 27px;
    width: auto;
  }
  .sidebar-menu {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding: {
      top: 9px;
      left: 20px;
      right: 20px;
      bottom: 30px;
    }
    .sidebar-navbar-nav {
      .sidebar-nav-item {
        margin-bottom: 12px;
        font: {
          size: 14px;
          weight: 400;
        }
        .accordion-button {
          text-decoration: none;
          letter-spacing: 0.01em;
          transition: var(--transition);
          color: var(--splash-dark-color);
          font: {
            size: 14px;
            weight: 400;
          }
          i {
            top: 50%;
            left: 10px;
            font-size: 18px;
            line-height: 0.01;
            position: absolute;
            transform: translateY(-50%);
          }
          &::after {
            top: 50%;
            right: 10px;
            width: auto;
            height: auto;
            margin-left: 0;
            content: "\e842";
            color: #222;
            position: absolute;
            background: unset !important;
            transform: translateY(-50%) rotate(180deg);
            font: {
              family: feather;
              size: 12px;
            }
          }
          &:hover {
            color: var(--splash-dark-color);
          }
          &.collapsed {
            color: #333;

            &:hover {
              color: var(--splash-dark-color);
            }
            &::after {
              transform: translateY(-50%);
            }
          }
        }
        .sidebar-nav-link {
          color: #333;
          position: relative;
          text-decoration: none;
          letter-spacing: 0.01em;
          padding: 0 25px 0 40px;
          transition: var(--transition);
          font: {
            size: 14px;
            weight: 400;
          }
          i {
            top: 50%;
            left: 10px;
            font-size: 18px;
            line-height: 0.01;
            position: absolute;
            transform: translateY(-50%);
          }
          &.active,
          &:hover {
            color: var(--base-color);
          }
        }
        .accordion-collapse {

            padding: {
              left: 15px;
              right: 0;
              bottom: 0;
            }
            .sidebar-sub-menu {
              .sidebar-sub-menu-item {
                .sidebar-sub-menu-link {
                  display: block;
                  color: #363636;
                  position: relative;
                  border-radius: 2px;
                  text-decoration: none;
                  letter-spacing: 0.01em;
                  transition: var(--transition);
                  padding: {
                    bottom: 11px;
                  }
                  font: {
                    size: 14px;
                    weight: 400;
                  }

                  .new-badge {
                    top: -1px;
                    z-index: 1;
                    padding: 0 5px;
                    font-size: 11px;
                    margin-left: 8px;
                    position: relative;
                    background: #6fd3f7;
                    color: var(--splash-black-color);

                    &::before {
                      top: 50%;
                      width: 8px;
                      height: 8px;
                      content: "";
                      z-index: -1;
                      left: -2.5px;
                      position: absolute;
                      background: #6fd3f7;
                      transform: translateY(-50%) rotate(45deg);
                    }
                  }
                  &.active {
                    color: var(--splash-dark-color);
                    background-color: var(--splash-primary-color);

                    &::before {
                      background: var(--splash-dark-color);
                    }
                  }
                  &:hover {
                    color: var(--splash-dark-color);

                    &::before {
                      background: var(--splash-dark-color);
                    }
                  }
                }
                .accordion-collapse {

                    padding: {
                      top: 0;
                      left: 24px;
                    }

                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

        }
        &.sub-title {
          span {
            letter-spacing: 0.05em;
            color: #222;
            font-size: 12px;
            margin: {
              top: 12px;
              bottom: 5px;
            }
          }
        }

      }
    }
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
      width: 5px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: #6d6c7d;
    }
    &::-webkit-scrollbar-track {
      background: #ccc;
    }
  }
}

  // Dark Mode
  .dark {
    .sidebar-area {
      .sidebar-menu {
        .sidebar-navbar-nav {
          .sidebar-nav-item {
            .accordion-button {
              &::after {
                filter: unset;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .sidebar-area {
      .border-top,
      .border-bottom {
        padding-top: 20px;
      }
      .sidebar-menu {
        padding: {
          top: 9px;
          left: 10px;
          right: 10px;
          bottom: 20px;
        }
        .sidebar-navbar-nav {
          .sidebar-nav-item {
            margin-bottom: 20px;

            .accordion-button {
              padding: 0 25px 0 16px;
              font-size: 15px;

              i {
                left: 10px;
              }
            }
            .sidebar-nav-link {
              padding: 0 25px 0 38px;
              font-size: 15px;

              i {
                left: 10px;
              }
            }
            .accordion-collapse {

                .sidebar-sub-menu {
                  .sidebar-sub-menu-item {
                    .sidebar-sub-menu-link {
                      font-size: 14px;
                      padding: {
                        top: 10px;
                        left: 38px;
                        bottom: 10px;
                      }
                    }
                    .accordion-button {
                      padding: {
                        top: 10px;
                        bottom: 10px;
                      }
                    }
                    .accordion-collapse {

                        padding-left: 15px;

                    }
                  }
                }

            }
            &.sub-title {
              span {
                margin: {
                  top: 20px;
                  bottom: 20px;
                }
              }
            }
          }
        }
      }
      &.active {
        left: 0 !important;
      }
    }
  }

